<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/staff' }">员工管理</el-breadcrumb-item>
        <el-breadcrumb-item>员工详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="姓名：" prop="">{{form.name}}</el-form-item>
        <el-form-item label="联系方式：" prop="">{{form.phone}}</el-form-item>
        <el-form-item label="性别：" prop="">{{ form.sex==1?'男':'女' }}</el-form-item>
        <el-form-item label="所属部门：" prop="">{{form.departmentName}}</el-form-item>
        <el-form-item label="岗位：" prop="">
          <template>
              <span v-for="(item, index) in form.positionInfos" :key="index">
                {{ form.positionInfos.length-1 > index ? item.positionName + '、' : item.positionName }}
              </span>
          </template> 
        </el-form-item>
        <el-form-item label="权限：" prop="">
          {{form.roleId==1?'超级管理员'
          :form.roleId==2?'高级管理员'
          :form.roleId==3?'管理员'
          :'普通员工'}}
        </el-form-item>
        <div v-if="form.roleId != 4 && form.roleId != null">
          <el-form-item label="管辖部门：">
            <el-collapse v-model="activeDepNames" @change="handleChange">
              <el-collapse-item title="管辖部门配置" name="1">
                <el-tree
                  ref="menuDepRefs"
                  :data="dataDepTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :check-strictly="checkDepStr"
                  :default-checked-keys="defaultDepKyes"
                  :props="depMenuProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </div>
        <el-form-item label="区域：" prop="">
          <template>
            <div v-if="form.regionInfos.length>0">
              <span v-for="(item, index) in form.regionInfos" :key="index">
                {{ form.regionInfos.length-1 > index ? item.regionName + '、' : item.regionName }}
              </span>
            </div>
            <div v-else>无</div>
          </template> 
        </el-form-item>
        <el-form-item label="册本：" prop="">
          <template>
            <div v-if="form.zoneInfos.length>0">
              <span v-for="(item, index) in form.zoneInfos" :key="index">
                {{ form.zoneInfos.length-1 > index ? item.zoneName + '、' : item.zoneName }}
              </span>
            </div>
            <div v-else>无</div>
          </template> 
        </el-form-item>
        <el-form-item label="菜单配置：">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="公司菜单配置" name="1">
              <el-tree
                ref="menuRefs"
                :data="dataTree"
                node-key="id"
                default-expand-all
                :props="defaultMenuProps">
              </el-tree>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <el-form-item label="">
          <el-button size="small" @click="$router.push('/staff')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      row: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        sex:[{ required: true, message: '必填项', trigger: 'blur' }],
        phone:[{ required: true, message: '必填项', trigger: 'blur' }],
        positionIdList:[{ required: true, message: '必填项', trigger: 'blur' }],
        roleId:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      departmentTreeData: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      positionFilter: [],
      position: [],
      activeNames: ['1'],
      activeDepNames: ['1'],
      dataTree: [],
      dataDepTree: [],
      defaultMenuProps: {
        children: 'childTree',
        label: 'title'
      },
      depMenuProps: {
        children: 'childTree',
        label: 'name'
      },
      defaultDepKyes: [],
      checkDepStr: false
    }
  },
  methods: {
    handleChange(val) {},
    loadDepartmentList() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTreeData = res.data;
        // 管辖部门数据
        this.dataDepTree = res.data
      });
    },
    departmentChange(id) {
      this.form.positionIdList = "";

      function filterFun(item) {
        return item.departmentId == id;
      }
      this.positionFilter = this.position.filter(filterFun);
    },
    loadPositionList() {
      this.$ajax.get("positionQueryAll").then((res) => {
        this.position = this.getTreeData(res.data);
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
              departmentId: item.departmentId,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    loadDetail() {
      this.loading = true
      this.$ajax.post('staffDetail', {
        id: this.row.id
      }).then(res => {
        res.data.companyId = this.row.companyId
        this.form = res.data
        this.dataTree = res.data.menuTrees
        // 回显管辖部门
        let respArr = []
        res.data.responsibleDepartmentInfos.forEach(v=>{
          respArr.push(v.departmentId)
        })
        this.defaultDepKyes = respArr
        this.checkDepStr = true
        this.$nextTick(() => {
          this.$refs.menuDepRefs.setCheckedKeys(this.defaultDepKyes)
        })
        setTimeout(()=>{
          this.disableTree(this.dataDepTree, this)
          this.checkDepStr = false
        },600)
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // el-tree全局禁用复选框
    disableTree(arr, that) {
      arr.forEach(item=>{
        that.$set(item, 'disabled', true)
        if(item.childTree && item.childTree.length>0) {
          this.disableTree(item.childTree, that)
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/staff')
      return
    }
    this.row = this.$route.params.row
    this.loadDetail()
    this.loadDepartmentList();
    this.loadPositionList();
  }
}
</script>
<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  width: 500px;
}
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
  font-size: 14px;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
.el-select {
  width: 100%;
}
</style>

<style>

</style>